<template>
  <div class="container titlesTop">
    <headTitle :title="title" />
    <van-pull-refresh
      v-model="refreshing"
      style="height: calc(100% - 0px); overflow-y: auto"
      @refresh="onRefresh"
    >
      <!-- <div v-if="isOnLoad">
        <loading></loading>
      </div> -->
      <van-swipe :show-indicators="false" :loop="false" class="myswipe">
        <van-swipe-item
          v-for="(itemOut, indexOut) in Math.ceil(tabData.length / 8)"
          :key="indexOut"
        >
          <div class="classSonBox">
            <div v-for="(item, index) in tabData.slice(
                indexOut * 8,
                (indexOut + 1) * 8
              )"
              class="classSon" 
              :key="index + 1"
              @click="enterClass(item)"
            >
              {{item.ugcTypeName}}
            </div>
          </div>
         
          <!-- <van-grid :gutter="10" :column-num="4" :border="false">
            <van-grid-item
              v-for="(item, index) in tabData.slice(
                indexOut * 8,
                (indexOut + 1) * 8
              )"
              :key="index + 1"
              :text="item.ugcTypeName"
              @click="enterClass(item)"
            />
          </van-grid> -->
        </van-swipe-item>
      </van-swipe>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无更多数据"
        @load="onLoad"
      >
        <list
          v-if="dynamicList && dynamicList.length > 0"
          :listContent="dynamicList"
          :isMy="isMy"
          ref="mychild"
        ></list>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import List from "./List";
// import Loading from "../Loading";
import InteractionApi from "@/api/interaction";
import { Toast } from "vant";

export default {
  props: ["sign"],
  components: {
    List,
    // Loading,
  },
  data() {
    return {
      dynamicList: [], // 动态列表
      tabData: [], // 分类导航数据
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 0,
      isOnLoad: true,
      ugcTypeId: "",
      userId: "",
      isMy: false,
      status: "",
      title: ''
    };
  },
  mounted() {
    if (this.$route.query.id === "mime") {
      this.isMy = true;
      this.userId = JSON.parse(window.localStorage.userinfo).id;
      this.title = '我的爆料'
    } else {
      this.ugcTypeId = this.$route.query.id;
      this.queryClassById(this.ugcTypeId);
      this.status = 2;
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.ugcTypeId = to.query.id;
    this.queryClassById(this.ugcTypeId);
    this.status = 2;
    this.onRefresh();
    next();
  },
  methods: {
    // 查询所有爆料
    queryAllInformation() {
      const params = {
        page: this.page,
        rows: 10,
        sortBy: "update_time",
        desc: true,
        UserId: this.userId,
        appName: this.appName,
        status: this.status,
        ugcTypeId: this.ugcTypeId,
      };
      // console.log(params);
      InteractionApi.getAllDynamic(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 10000) {
            // this.isOnLoad = false;
            this.loading = false;
            this.refreshing = false;
            // if (res.data.items && res.data.items.length > 0) {
              if (this.page === 1) {
                this.dynamicList = [];
              }
              this.dynamicList = this.dynamicList.concat(res.data.items);
              if(!res.data.items||res.data.items.length<10){
                this.finished = true;
              }else{
                this.finished = false;
              }
            // }else {
            //   this.finished = true;
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoad() {
      this.page += 1;
      this.queryAllInformation();
    },
    onRefresh() {
      this.page = 1;
      if (this.dynamicList && this.dynamicList.length) {
        this.$refs.mychild.closeAudio();
        this.$refs.mychild.closeVedio();
      }
      this.queryAllInformation();
    },
    // 根据分类Id查询当前分类信息
    queryClassById(id) {
      InteractionApi.getClassDetail({ typeId: id }).then((res) => {
        // console.log(res.data.id);
        this.tabData = res.data.children;
        this.title = res.data.ugcTypeName;
      });
    },
    enterClass(val) {
      // 进入分类页之前，先关闭当前播放的视频和音药
      let link;
      link = window.location.href.split("#/")[0] + "#/classPage?id=" + val.id;
      if (this.dynamicList && this.dynamicList.length) {
        this.$refs.mychild.closeAudio();
        this.$refs.mychild.closeVedio();
      }
      window.location.href = link;
    }
  },
};
</script>
<style>
.van-grid-item__text {
  font-size: 0.3rem;
  /* background: red; */
}
</style>
<style lang="less" scoped>
.classSonBox{
  width: 100%;
  // padding: 0 3vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 3vw;
  align-items: center;
  .classSon{
    padding: 5px 0;
    width: 21.25vw;
    background: #f3f7fa;
    border-radius: 3px;
    margin-top: 3vw;
    margin-right: 3vw;
    text-align: center;
  }
}
</style>